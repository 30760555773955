import React from 'react';
import styled from 'styled-components';
import { Container } from 'react-bootstrap';
import withElection from '~/helpers/providers/withElection';
import BackgroundImage from './background.jpg';
import { Election } from '~/helpers/types/Election';

/** Styled background div, with background image. */
const Background = styled.div`
  width: 100%;
  height: 100vh;
  background-size: cover;
  background:
  linear-gradient(rgba(0,0,0,0.5),
  rgba(0,0,0,0.5)),
  url('${BackgroundImage}');
  vertical-align: center;
  display: grid;
  place-items: center center;
`;

/** Styled text. */
const Text = styled.h3`
  text-align: center;
  color: white;
`;

type PollsAreClosedProps = {
  /** Details of the election. */
  election: Election;
};

/**
 * Component that is shown in place of the app once 'pollsAreClosed' is set in config.
 * Currently the text for this is ad-hoc changed each election.
 * Need to be refactored to pull contents from election/config.
 */
const PollsAreClosed: React.SFC<PollsAreClosedProps> = ({ election }) => (
  <Background>
    <Container>
      <div className="call-to-action call-to-action--primary">
        <div className="call-to-action__inner">
          <h2 className="call-to-action__title"><span style={{ color: 'white' }}>Early voting in the QLD State election starts Monday 14th October</span></h2>
          <p style={{ color: 'white' }}>You can view our How To Vote information here from Monday.</p>
        </div>
      </div>
    </Container>
  </Background>
);

export default withElection(PollsAreClosed);
