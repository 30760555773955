import React from 'react';
import { Container } from 'react-bootstrap';
import styled from 'styled-components';
import { urlSafeElectorate } from '~/helpers/constants/Electorates';

import { GreensLogo } from '@australiangreens/components';

// const Divider = styled.div`
//   width: 100%;
//   border-bottom: 2px solid black;
//   margin-top: 10px;
// `;

/**
 * A box where the party logo goes, if one is shown.
 * Rendered on all entries if showGreensLogoOnBallot is set,
 * but left blank if this entry isn't for the Greens candidate.
 */
const PartyLogo = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  width: 35px;
  height: 35px;
`;

/**
 * A div for the candidate name.
 * Empty but initialised as a pseudo-component for consistency.
 */
const CandidateName = styled.div``;

/**
 * A box for the voting square.
 */
const VotingSquareContainer = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 40px;
  height: 40px;
`;

/**
 * The voting square itself.
 * May contain a preference or be empty.
 */
const VotingSquare = styled.div`
  border: 1px solid black;
  width: 40px;
  height: 40px;
  text-align: center;
  font-size: 24px;
  line-height: 40px;
  margin-left: auto;
  margin-right: 10px;
`;

/**
 * Text for the party name.
 * Can be empty if it's an independent.
 */
const Party = styled.p`
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 12px;
  color: black;
`;

/**
 * Container for the logo.
 */
const LogoContainer = styled.div`
  text-align: right;
  padding-top: 5px;

  @media (max-width: 500px) {
    padding-right: 10px;
  }
`;

type PreferenceProps = {
  /** Array of Greens parties names as they appear on the ballot for this election.
   * This is an array because for Federal elections,
   * different state parties may appear with differing names.
   */
  partyNameOnBallot: string[];
  /** The numerical preference for this entry on the ballot.
   * Can be blank for an "open ballot". */
  preference?: number;
  /** The name of the electorate.
   * Only used for generating links to candidate pages. */
  electorateName: string;
  /** The party name for this entry as it appears on the ballot.
   * Can be blank for an independent candidate in an election
   * where "independent" should not show beneath their name.
   */
  party?: string;
  /** The first name of this ballot entry as it appears on the ballot. */
  firstName: string;
  /** The last name of this ballot entry, as it appears on the ballot.  */
  lastName: string;
  /** Should the Greens logo be shown if this entry is for the Greens candidate?
   * (Also used to determine if an empty logo-sized spot should be present for non-Greens candidate.)
   */
  showGreensLogoOnBallot?: boolean;
/** Should the logo be shown for this candidate, regardless of whether they are a Greens candidate? */
  showAllLogosOnBallot?: boolean;
  /** Should the first & last name be bolded if this entry is for the Greens candidate? */
  boldGreensCandidate?: boolean;
  /** Should the first & last name be linked to the candidate's webpage on greens.org.au
   * if this entry is for a Greens candidate?
   */
  linkCandidateOnBallot?: boolean;
};

/**
 * Represents a single entry on a ballot paper.
 */
const Preference: React.SFC<PreferenceProps> = ({
  firstName,
  lastName,
  preference,
  party,
  electorateName,
  showGreensLogoOnBallot,
  boldGreensCandidate,
  partyNameOnBallot,
  linkCandidateOnBallot,
}) => {
  const isGreensPreference = (partyNameOnBallot.includes(party));
  let Candidate;
  if (isGreensPreference && linkCandidateOnBallot) {
    Candidate = styled.a.attrs({ href: `https://greens.org.au/candidates/${urlSafeElectorate(electorateName)}`, target: '_blank' })`
      ${boldGreensCandidate && isGreensPreference && 'font-weight: bold;'};
    `;
  } else {
    Candidate = styled.span<PreferenceProps>`
    color: black;
    font-weight: ${(props) => (props.boldGreensCandidate && isGreensPreference ? 'bold' : 'normal')};
    `;
  }
  const Row = styled.div`
    position: relative;
    ${!showGreensLogoOnBallot && 'padding-left: 50px;'} ${showGreensLogoOnBallot && 'padding-left: 45px;'};
  `;
  return (
    <Container>
      <Row>
        {showGreensLogoOnBallot && (
          <PartyLogo>
            <LogoContainer>{isGreensPreference && <GreensLogo width="35px" bw />}</LogoContainer>
          </PartyLogo>
        )}
        <VotingSquareContainer>
          <VotingSquare>{preference}</VotingSquare>
        </VotingSquareContainer>
        <CandidateName>
          <Candidate boldGreensCandidate>
            {lastName}, {firstName}
          </Candidate>
          {/* Commented out for TAS24 */}
          <Party>{party?.toUpperCase() || '\u00A0'}</Party>
        </CandidateName>
      </Row>
    </Container>
  );
};

Preference.defaultProps = {
  party: null,
  preference: null,
  showGreensLogoOnBallot: false,
  showAllLogosOnBallot: false,
  boldGreensCandidate: false,
  linkCandidateOnBallot: false,
};

export default Preference;
