import React from 'react';
import { Container } from 'react-bootstrap';

/**
 * Component for the 2024 QLD LGA "Why Vote Green" section.
 */
export const QLD24WVG = () => (
  <Container>
    <div className="container pt-1">
      <p align="center"><strong>More for Queenslanders, less for big corporations</strong></p>
      <p>With more Greens in Parliament, we&apos;ll fight to address the cost of living and housing crisis, tackle climate change, and tax big corporations fairly to fund essential services. You can find details of our policy platform for the 2024 State election below.</p>
      <ul>
        <li><strong>Freeze rents for 2 years, then cap rent increases</strong> at 1% per year after that, and guarantee lease renewals</li>
        <li><strong>Make mortgages cheaper</strong> with a public bank to offer cheap home loans</li>
        <li><strong>Cap grocery prices</strong> on 30 basic essentials and break up Coles &amp; Woolies</li>
        <li><strong>Build 100,000 public homes</strong> to rent and buy at prices you can afford</li>
        <li><strong>Stop Labor and LNP&apos;s 37 new coal mines</strong>, and transition to 100% renewables</li>
      </ul>
      <p>Real change starts with electing someone who&apos;ll fight for you and what you believe in. <a target="_new" href="https://greens.org.au/qld/plan">You can read our full plan for Queensland here</a>.</p>
    </div>
  </Container>
);

export default QLD24WVG;
